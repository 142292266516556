<template>
  <el-container class="list file">
    <avue-crud ref="crud"
               style="display:none"
               :option="option"
               v-model="form"
               :page.sync="page"
               v-loading="loading"
               v-bind="$loadingParams"
               :before-open="beforeOpen"
               :data="data"
               @on-load="onLoad">
    </avue-crud>
    <el-header class="content__header">
      <div class="content__box content__nav">
        <div></div>
        <div class="content__page">
          <el-pagination v-if="page.total>0"
                         layout="total, prev, pager, next"
                         background
                         size="small"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :page-size="page.pageSize"
                         :current-page.sync="page.currentPage"
                         :total="page.total">
          </el-pagination>
        </div>
      </div>
    </el-header>
    <el-main class="content"
             v-loading="loading"
             v-bind="$loadingParams">
      <div class="content__box">
        <template v-if="data.length>0">
          <div class="content__item"
               @mouseenter="item._menu=true"
               @mouseleave="item._menu=false"
               @click="select(item,index)"
               v-for="(item,index) in data"
               :key="index">
            <div class="content__main">
              <span class="content__name">{{item.title}}</span>
              <span class="content__size">{{ item.requestUri }}</span>
              <span class="content__type">{{ item.remoteIp }}</span>
              <span class="content__time">{{ item.createTime }}</span>
            </div>

            <div class="content__menu">

            </div>
          </div>
        </template>
        <el-empty v-else
                  class="content__empty"
                  description="暂无数据">
          <template #image>
            <svg-icon icon-class="empty" />
          </template>
        </el-empty>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getList, getObj } from '@avue/avue-data/api/logs'
import { url } from '@avue/avue-data/config';
export default {
  props: {
    menu: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      search: {},
      url,
      loading: false,
      form: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      data: [],
      option: {
        dialogWidth: '600',
        dialogMenuPosition: 'center',
        height: 'auto',
        calcHeight: 330,
        header: false,
        index: true,
        align: 'center',
        headerAlign: 'center',
        column: [
          {
            label: '名称',
            prop: 'title',
          }, {
            label: '地址',
            prop: 'requestUri'
          },
          {
            label: 'ip',
            prop: 'remoteIp',
            span: 24
          },
          {
            label: '上传时间',
            prop: 'createTime',
            span: 24
          }
        ]
      }
    }
  },
  methods: {
    vaildData (id) {
      return [0, 1, 2].includes(id)
    },
    beforeOpen (done, type) {
      if (type == 'edit') {
        getObj(this.form.id).then(res => {
          const data = res.data.data;
          this.form = data
          done()
        })
      } else {
        done()
      }
    },

    handleCurrentChange (val) {
      this.page.currentPage = val;
      this.onLoad();
    },
    handleSizeChange (val) {
      this.page.pageSize = val;
      this.onLoad();
    },
    onLoad () {
      this.loading = true
      getList({
        assetsName: this.search.name,
        current: this.page.currentPage,
        size: this.page.pageSize,
      }).then(res => {
        this.loading = false
        const data = res.data.data;
        let records = data.records
        records.forEach(ele => ele._menu = false);
        this.page.total = data.total;
        this.data = records;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.file {
  .content {
    &__info {
      height: auto;
    }
    &__item {
      margin-right: 0;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }
    &__logo {
      margin-right: 10px;
      img {
        object-fit: cover;
        width: 40px !important;
        height: 40px !important;
      }
    }
    &__main {
      flex: 1;
      justify-content: flex-start;
    }
    &__menu {
      width: 130px !important;
      margin-right: 10px;
      position: relative;
      width: 100px;
      height: inherit;
      right: 0;
      display: inline-block;
    }
    &__name {
      width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__size {
      width: 200px;
      margin-right: 100px;
    }
    &__type {
      width: 200px;
      margin-right: 100px;
    }
    &__time {
      width: 200px;
    }
  }
}
</style>